import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  margin-bottom: 80px;
`;

export const Slider = styled.div`
  overflow-x: auto;
  display: flex;
  padding-bottom: 15px;
  > div {
    display: flex;
    flex-shrink: 0;
    padding: 0 17px;
  }
`;

export const Item = styled.div`
  width: 89vw;
  flex-shrink: 0;
  background-color: #282933;
  padding-bottom: 55px;
  &:not(:last-of-type) {
    margin-right: 8px;
  }
  > div {
    position: relative;
    top: 0;
    left: 0;
    img {
      width: 100%;
    }
    h2 {
      position: absolute;
      max-width: 90%;
      color: #fff;
      font-size: 27px;
      font-weight: bold;
      letter-spacing: -0.5px;
      left: 40px;
      bottom: 20px;
      div {
        margin-bottom: 7px;
        span {
          display: inline-block;
          height: 13px;
          width: 13px;
          border: 1.5px solid #f7651c;
          margin-right: 10px;
        }
      }
    }
  }
  p {
    max-width: 90%;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    padding-left: 40px;
    padding-top: 20px;
  }
`;

export const Dots = styled.ul`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 45px;
  left: 0;
  li {
    height: 8px;
    width: 8px;
    opacity: 0.5;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 8px;
    ${(props) => css`
      &:nth-of-type(${props.slidePosition}) {
        height: 14px;
        width: 14px;
        opacity: 1;
      }
    `}
  }
`;
