import styled, { css } from "styled-components";

import { px2rem } from "../../utils";

export const Container = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: 997px) {
    flex-direction: column-reverse;
    > img {
      width: 100%;
    }
  }
`;

export const Map = styled.div`
  position: relative;
  max-width: 45.5%;
  width: 100%;
  overflow: hidden;
  left: 0;
  top: 0;
  > img {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transform: scale(1.2);
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: ${px2rem(80)};
  margin-left: ${px2rem(48)};
  @media screen and (max-width: 997px) {
    margin-top: 87px;
    margin-left: 0;
  }
  h1 {
    color: #282933;
    font-size: ${px2rem(40)};
    font-weight: bold;
    line-height: ${px2rem(48)};
    @media screen and (max-width: 997px) {
      font-size: 32px;
      line-height: 40px;
      margin: 0 40px;
    }
  }
  form {
    > div {
      transition: opacity 0.3s ease;
    }
    ${(props) =>
      props.submit === "sending" &&
      css`
        > div {
          opacity: 0.6;
        }
      `}
    @media screen and (min-width: 998px) {
      max-width: ${px2rem(632)};
      > div {
        display: flex;
      }
    }
  }
`;

export const InputContent = styled.div`
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  color: #282933;
  margin-top: ${px2rem(63)};
  @media screen and (min-width: 998px) {
    padding-left: ${px2rem(33)};
  }
  @media screen and (max-width: 997px) {
    padding: 0 40px;
  }
  label {
    z-index: -1;
    position: absolute;
    font-size: ${px2rem(16)};
    font-weight: 400;
    line-height: ${px2rem(20)};
    transition: 0.1s;
    top: 0;
    left: ${px2rem(33)};

    @media screen and (max-width: 997px) {
      left: 40px;
    }
  }
  input,
  textarea {
    width: 100%;
    font-size: ${px2rem(16)};
    font-weight: 300;
    line-height: ${px2rem(24)};
    outline: 0;
    padding-bottom: ${px2rem(2)};
    border-bottom: 1px solid #282933;
    &::placeholder {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
    &:focus + label,
    :not(:placeholder-shown) ~ label {
      font-size: ${px2rem(12)};
      top: ${px2rem(-26)};
    }
  }

  &.textarea-content {
    min-height: ${px2rem(105)};
    margin-top: ${px2rem(90)};
    label {
    }
    textarea {
      width: 100%;
    }
  }
`;

export const ButtonWrapper = styled.div`
  opacity: 1 !important;
  padding-left: ${px2rem(33)};
  @media screen and (max-width: 997px){
    padding: 0 40px;
  }
  button{
    position: relative;
    width: 100%;
    height: ${px2rem(55)};
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    font-size: ${px2rem(16)};
    font-weight: 300;
    line-height: ${px2rem(24)};
    background-color: #F7651C;
    box-shadow: 0 3px 16px #F7651C63;
    outline: 0;
    margin-top: ${px2rem(24)};
    margin-bottom: ${px2rem(71)};
    ${(props) =>
      props.submit === "sended" &&
      css`
        background-color: #f7651c;
        pointer-events: none;
      `}   
    ${(props) =>
      props.submit === "sending" &&
      css`
        color: #f7651c;
        background-color: #fff;
      `}
    ${(props) =>
      props.submit === "error" &&
      css`
        color: #fff;
        background-color: #b51010;
      `}
    &:disabled{
      opacity: 0.5;
      pointer-events: none;
    }
    @media screen and (max-width: 997px){
      max-width: 100%
    }
    @media screen and (min-width: 998px) {
      position: relative; 
      overflow: hidden;
      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        clip-path: circle(0% at 50% 150%);
        transition: clip-path 0.3s linear -0.1s, clip-path 0.3s linear -0.1s, z-index 0s 0.3s;
        z-index: -1;
        color: #F7651C;
        background-color: #fff;
        svg{
          transition: 0s linear 0.2s;
          fill: #F7651C;
          stroke: #F7651C;
        }
      }
      &:focus,
      &:hover {
        transition: background-color 0s linear 0.2s,
        background-image 0s linear 0.2s;
        background-image: none !important;
        background-color: transparent !important;
        span {
          clip-path: circle(121% at 50% 150%);
          transition: clip-path 0.3s linear, -webkit-clip-path 0.3s linear;
          z-index: 0;
        }
      }
    }
    svg{
      height: ${px2rem(13)};
      fill: #fff;
      stroke: #fff;
      margin-left: ${px2rem(18)};
      transition: clip-path 0.3s linear -0.1s, z-index 0s ease 0.3s;
    }
  }
`;

export const ErrorMessage = styled.span`
  position: absolute;
  top: ${px2rem(32)};
  left: ${px2rem(33)};
  font-size: ${px2rem(12)};
  color: #ff0000;
  @media screen and (max-width: 997px) {
    left: ${px2rem(40)};
  }
`;
