import * as Yup from 'yup';

const validationSchema = Yup.object({
  name: Yup.string()
    .required('Campo necessário'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('Campo necessário'),
  phone: Yup.string()
    .required('Campo necessário'),
  company: Yup.string()
    .required('Campo necessário'),
  position: Yup.string()
    .required('Campo necessário'),
  message: Yup.string()
    .required('Campo necessário'),
})

export default validationSchema;