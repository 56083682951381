import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import Grid from '../../../Grid';
import { Wrapper, TextContainer, TextWrapper, Animation, HeightSet} from './styles';

function TopicDefault({ children, image, isTextRight }){
  const [animation, setAnimation] = useState(false);

  return(
    <VisibilitySensor partialVisibility offset={{ bottom: 350 }} onChange={(visibility) => {visibility && setAnimation(true)}}>
      <Wrapper textRight={isTextRight}>
        <Grid>
          <TextContainer textRight={isTextRight}>
            <TextWrapper textRight={isTextRight}>
              {children}
            </TextWrapper>
          </TextContainer>
        </Grid>
        <Animation animate={animation} textRight={isTextRight}>
          <span />
        </Animation>
        <img src={require(`../../../../assets/${image}`)} alt="Funcionarios da Veled" />
        <HeightSet />
      </Wrapper>
    </VisibilitySensor>
  )
}

export default TopicDefault;