import React from 'react';
import { Element } from 'react-scroll'

import { Desktop, Mobile } from '../Responsivity';

import SomosVeled from '../SomosVeled';
import TopicList from './TopicList';
import TopicSlider from './TopicSlider';

export default function Topics(){
  return(
    <>
      <Element name="somos-a-veled">
        <SomosVeled />
        <Desktop><TopicList /></Desktop>
        <Mobile><TopicSlider /></Mobile>
      </Element>
    </>
  )
}