import React, { useState, useEffect } from 'react';
import ScrollLock from 'react-scrolllock';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Mobile } from '../../Responsivity';
import { Overlay, PopUp, Photo, Description, CloseButton } from './styles';

import especialistas from '../especialistas.json';

function Modal(){
  const match = useRouteMatch();
  const history = useHistory();
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {setAnimation(true)}, 0);
  },[])

  function onClose(){
    setAnimation(false)
    setTimeout(() => {history.push("/")}, 300);
  }

  return(
    <Overlay animation={animation}>
    <div animation={animation}>
      <ScrollLock>
        <PopUp>
          <header onClick={onClose}>
            <button><img src={require("../../../assets/close-icon.svg")} alt="icone fechar modal"/></button>
          </header>
          {especialistas.map(espec => (
            match.params.slug === espec.path  &&
            <div>
              <Photo>
                <picture>
                  <source media="(max-width:997px)" srcSet={require(`../../../assets/especialista-${espec.id}-mobile-detail.jpg`)} />
                  <source media="(min-width:998px)" srcSet={require(`../../../assets/especialista-${espec.id}-desktop.jpg`)} />
                  <img src={require(`../../../assets/especialista-${espec.id}-desktop.jpg`)} alt={`foto ${espec.name}`} />
                </picture>
              </Photo>
              <Description>
                <section>
                  <div>
                    <h1>{espec.name}</h1>
                    <a title="Visitar Linkedin" href={espec.linkedin} target="_blank">
                      <img src={require("../../../assets/linkedin-icon.svg")} alt="icone linkedin" />
                    </a>
                  </div>
                  <span>{espec.position}</span>
                  <strong>{espec.infoTitle}</strong>
                  {espec.fullDescription.map(paragraph => (
                    <p>{paragraph}</p>
                  ))}
                </section>
              </Description>
            </div>
          ))}
          <Mobile><CloseButton onClick={onClose}>Fechar</CloseButton></Mobile>
        </PopUp>
      </ScrollLock>
    </div>
    </Overlay>
  )
}

export default Modal;