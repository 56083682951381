import React from 'react';
import { Element } from 'react-scroll';

import { ReactComponent as RightArrow } from '../../assets/right-arrow.svg';

import Grid from '../Grid';
import { Desktop } from '../Responsivity';
import { Container, Wrapper, PhotoWrapper, InfoLink, LinkModal} from './styles';

import especialistas from './especialistas.json';

function NossosEspecialistas() {

  return (
    <Element name="nossos-especialistas">
      <Container>
        <Desktop><img src={require("../../assets/background-especialistas.png")} alt="imagem de plano de fundo" /></Desktop>
        <Grid>
          <h1>Conheça nossos <br /> especialistas</h1>
          <Wrapper>
            <div>
              {especialistas.map(espec => (
                <PhotoWrapper>
                  <picture>
                    <source media="(max-width:997px)" srcSet={require(`../../assets/especialista-${espec.id}-mobile-detail.jpg`)} />
                    <source media="(min-width:998px)" srcSet={require(`../../assets/especialista-${espec.id}-desktop.jpg`)} />
                    <img src={require(`../../assets/especialista-${espec.id}-desktop.jpg`)} alt={`foto ${espec.name}`} />
                  </picture>
                    <section>
                      <strong>
                        <span>{espec.name}</span> 
                        <a title="Visitar Linkedin" href={espec.linkedin} target="_blank">
                          <img src={require("../../assets/linkedin-icon.svg")} alt="icone linkedin" />
                        </a>
                      </strong>
                      <p>{espec.position}</p>
                      <LinkModal title={`Ver mais sobre ${espec.name}`}  to={`/sobre/${espec.path}`}>
                          <p>{espec.description}</p>
                        <InfoLink>
                          <span>Ver mais <RightArrow /></span>
                          <hr />
                        </InfoLink>
                      </LinkModal>
                    </section>
                </PhotoWrapper>
              ))}
            </div>
          </Wrapper>
        </Grid>
      </Container>
    </Element>
  )
}

export default NossosEspecialistas;