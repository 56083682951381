import styled from 'styled-components';

import { px2rem } from '../../utils';

export const Wrapper = styled.div`
  z-index: 5;
`;

export const LogoText = styled.p`
  @media screen and (min-width: 997px){   
    &:first-of-type{
      margin-top: ${px2rem(18)};
    }
  }
  font-family: "Sora";
  font-weight: 400;
  text-align: right;
  color: #fff;
  text-transform: uppercase;
`;