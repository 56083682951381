import React, { useState, useEffect } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import ScrollLock from 'react-scrolllock';

import Grid from '../Grid';
import { Desktop, Mobile } from '../Responsivity';
import LogoVeled from '../LogoVeled';
import { HeaderWrapper, LogoWrapper, MenuButton, NavigationBar, CloseButton, OpenButton, Link, BackgroundColor } from './styles';

function Header(){
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState(2);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    setStartAnimation(true)
    if(document.documentElement.scrollTop > 650){
      setMenuPosition(0);
    }
  },[])

  useScrollPosition(({ prevPos, currPos }) => {
    if((-prevPos.y <= 650) && (-currPos.y > 650)){
      setMenuPosition(0);
    }else{
      if((-prevPos.y >= 650) && (-currPos.y < 650)){
        setMenuPosition(1);
        if(window.matchMedia('(min-width: 998px)').matches){
          setTimeout(() => {
            setMenuPosition(2);
          }, 200);
        }else{
          setMenuPosition(2);
        }
      }
    } 
  })

  const scrollLinkDefaultProps = {
    duration: 800,
    smooth: true,
    spy: true,
    offset: -90
  };


  const menuOptions = [
    "Somos a Veled",
    "Nossos especialistas",
    "Nossas Entregas",
    "Fale com a Veled"
  ]

  return(
    <HeaderWrapper menuPosition={menuPosition}>
      <Grid >
        <LogoWrapper menuPosition={menuPosition} animate={startAnimation}>
          <Desktop><LogoVeled text={menuPosition === 2}/></Desktop>
          <Mobile><LogoVeled text={false}/></Mobile>
          <BackgroundColor menuPosition={menuPosition} animate={startAnimation}>
            <div><div /></div>
            <div />
          </BackgroundColor>
        </LogoWrapper>

        <Mobile>
          <MenuButton onClick={() => setShowSideMenu(true)}>
            <img src={require("../../assets/menu-icon.svg")} alt="botão abrir menu"/>
          </MenuButton>
        </Mobile>
        
        <NavigationBar menuPosition={menuPosition} showSideMenu={showSideMenu} animate={startAnimation}>
          <Mobile>
            <ScrollLock isActive={showSideMenu}/>
            <OpenButton onClick={() => setShowSideMenu(false)}>
              <img src={require("../../assets/close-icon.svg")} alt="botão fechar menu"/>
            </OpenButton>
            <LogoVeled text={false}/>
          </Mobile>
          <ul>
            {menuOptions.map((option, key) => (
              <li key={`option${key}`}>
                <Link
                  menuposition={menuPosition}
                  title={option}
                  onClick={() => setShowSideMenu(false)}
                  {...scrollLinkDefaultProps} 
                  to={option.replace(/ /g,"-").toLowerCase()}
                >
                  {option}
                  <Desktop><hr/></Desktop>
                </Link>
              </li>
            ))}
          </ul>
          <Mobile><CloseButton onClick={() => setShowSideMenu(false)}>Fechar</CloseButton></Mobile>
        </NavigationBar>
      </Grid>        
    </HeaderWrapper>
  )
}

export default Header;