import React from 'react';

import { Mobile } from '../Responsivity';
import Grid from '../Grid';
import LogoVeled from '../LogoVeled';
import { ReactComponent as LinkedinIcon } from '../../assets/linkedin-icon.svg';
import { Container, Wrapper } from './styles';

function Footer() {
  return (
    <Container>
      <Grid>
        <Mobile>
          <LinkedinIcon />
        </Mobile>
        <Wrapper>
          <section>
            <strong>Contatos</strong>
            <hr />
            <p>tadeugualtieri@veledrh.com.br</p>
            <p>renatobalduino@veledrh.com.br</p>
          </section>
          <section>
            <strong>Endereço</strong>
            <hr />
            <div>
              <img
                src={require('../../assets/map-marker-icon.svg')}
                alt="icone marcador para mapas"
              />
              <p>
                R. Samuel Morse, 74 – 3° andar <br />
                Conj. 34 – Edifício Igarata <br />
                CEP: 04576-060 – São Paulo–SP
              </p>
            </div>
          </section>
        </Wrapper>
        <LogoVeled />
      </Grid>
    </Container>
  );
}

export default Footer;
