import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import { Wrapper, TextWrapper } from './styles';
import { Animation } from "./TopicDefault/styles";
import TopicDefault from './TopicDefault';

function TopicList(){
  const [animation, setAnimation] = useState(false);

  return (
    <>
      <TopicDefault image="topic-1.jpg" >
        <h2>
          <div><span /><span /><span /></div>
          Experiência <br /> e conhecimento <br /> de mercado.
        </h2>
        <p>A Veled entende que é preciso ir além daquilo que está naturalmente explícito. 
        Alinhamento de propósitos, identificação com os traços culturais e valores da sua organização 
        são determinantes no nosso processo.</p>
      </TopicDefault>
      <TopicDefault image="topic-2.jpg" isTextRight>
        <h2>
          <div><span /><span /><span /></div>
          Partnership de RH para empresas com visão estratégica de RH.
        </h2>
        <p>A Veled nasceu para ser um parceiro estratégico das áreas de RH, 
          participando da co-criação das estratégias, planos e decisões, 
          indo além da condução de projetos específicos de seleção de executivos.</p>
      </TopicDefault>
      <TopicDefault image="topic-3.jpg" >
        <h2>
          <div><span /><span /><span /></div>
          Projetos de RH com a máxima customização possível.
        </h2>
        <p>Os especialistas da Veled vão fundo para entender a cultura, 
          os valores e o perfil do seu time. É o único método consistente para construir 
          as melhores equipes para executar a transformação que sua companhia precisa.</p>
      </TopicDefault>

      <VisibilitySensor partialVisibility offset={{ bottom: 350 }} onChange={(visibility) => {visibility && setAnimation(true)}}>
        <Wrapper>
          <Animation redBackground textRight animate={animation}>
            <span />
          </Animation>
          <img src={require("../../../assets/plano-carreira.jpg")} alt="funcionaria Veled" />
          <TextWrapper>
            <h2>
              <div><span /><span /><span /></div>
              Plano de carreira? <br/> Nós chamamos de planejamento de vida.
            </h2>
            <p>A carreira tem impacto direto na vida de uma pessoa. <br/>Por isso a Veled 
              considera tão importante o perfil de cada executivo, seus valores, habilidades, 
              vocações, tendências do mercado e momento pessoal.</p>
          </TextWrapper>
        </Wrapper>
        </VisibilitySensor>
    </>
  )
}

export default TopicList;