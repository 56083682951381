import styled, { css } from "styled-components";

import { px2rem } from "../../utils/";

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  background-color: #282933;
  div:first-of-type {
    max-width: 54.5%;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 45.5%;
  }
`;

export const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;

  @media screen and (max-width: 997px) {
    background-color: #282933;
    padding-top: 86px;
    padding-bottom: 78px;
  }
  @media screen and (min-width: 998px) {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  h1 {
    color: #fff;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.8px;
    padding-bottom: 34px;
    padding-left: 40px;

    @media screen and (min-width: 998px) {
      position: absolute;
      left: 45.5%;
      bottom: calc(${px2rem(40)} + ${px2rem(600)} + ${px2rem(57)});
      transform: translateX(calc(-100% - ${px2rem(230)}));
      padding: 0;
      font-size: ${px2rem(64)};
      line-height: ${px2rem(64)};
    }
  }
`;

export const Slider = styled.div`
  overflow-x: auto;
  display: flex;
  padding-left: 20px;
  padding-bottom: 15px;

  div {
    flex-shrink: 0;
    width: 92%;
    max-width: 400px;

    &:first-of-type {
      section {
        background-color: #f7651c;
        color: #fff;

        @media screen and (min-width: 998px) {
          transform: translateX(calc(-100% - 30px));
        }
      }
    }
    &:last-of-type {
      section {
        background-color: #fff;
      }
    }
    section {
      height: 100%;
      margin-right: 20px;
      padding: 32px;

      @media screen and (min-width: 998px) {
        position: absolute;
        height: ${px2rem(600)};
        width: ${px2rem(483)};
        bottom: ${px2rem(40)};
        left: 45.5%;
        padding-left: ${px2rem(50)};
        padding-top: ${px2rem(86)};
      }
      h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;

        @media screen and (min-width: 998px) {
          font-size: ${px2rem(40)};
          line-height: ${px2rem(48)};
        }
      }
      strong {
        display: block;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;

        @media screen and (min-width: 998px) {
          font-size: ${px2rem(24)};
          line-height: ${px2rem(32)};
          margin-top: ${px2rem(26)};
        }
      }
      p {
        font-weight: 400;
        margin-top: 24px;

        @media screen and (min-width: 998px) {
          font-size: ${px2rem(16)};
          line-height: ${px2rem(24)};
          margin-top: ${px2rem(19)};
        }
      }
    }
  }
`;

export const Dots = styled.ul`
  @media screen and (min-width: 650px) {
    display: none;
  }
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 42px;
  left: 0;
  li {
    height: 8px;
    width: 8px;
    opacity: 0.5;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 8px;
    ${(props) => css`
      &:nth-of-type(${props.slidePosition}) {
        height: 14px;
        width: 14px;
        opacity: 1;
      }
    `}
  }
`;

export const Animation = styled.div`
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 54.5%;
  top: 0;
  left: 45.5%;
  span {
    position: absolute;
    width: 200%;
    height: 100%;
    top: 0;
    right: -50%;
    background-color: #282933;
    transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1),
      right 1.5s cubic-bezier(0.16, 1, 0.3, 1);
    transform: skew(-32deg, 0);
    ${(props) =>
      props.animate &&
      css`
        transform: skew(0, 0);
        right: 100%;
      `}
  }
`;
