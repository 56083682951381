import React, {  useState } from 'react';
import { Route } from "react-router-dom";

import Header from '../components/Header';
import Apresentation from '../components/Apresentation';
import Topics from '../components/Topics';
import NossosEspecialistas from '../components/NossosEspecialistas';
import Modal from '../components/NossosEspecialistas/Modal';
import NossasEntregas from '../components/NossasEntregas';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

function Home(){
  const [animationMenu, setAnimationMenu] = useState(false)

  return(
    <>
      <Route path="/sobre/:slug" component={Modal}/>
      {animationMenu && <Header />}
      <Apresentation setAnimationMenu={setAnimationMenu} />
      <Topics />
      <NossosEspecialistas /> 
      <NossasEntregas />
      <Contact />
      <Footer />
    </>
  )
}

export default Home;