import styled, { css } from "styled-components";
import { Link as ReactScrollLink } from "react-scroll";

import { px2rem } from "../../utils";

export const HeaderWrapper = styled.header`
  z-index: 4;
  overflow: hidden;
  width: 100%;
  height: ${px2rem(220)};
  top: 0;
  left: 0;
  /* GRID */
  >div:last-of-type{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width: 997px){
    height: 90px;
    position: fixed;
    >div{
      padding: 0;
    }
  }
  @media screen and (min-width: 998px){
    ${(props) =>
      props.menuPosition !== 2 &&
      css`
        transition: top 0.3s ease;
        position: fixed;
        height: ${px2rem(90)};
        svg {
          width: ${px2rem(190)};
        }
      `}
      
    ${(props) =>
      props.menuPosition === 0 &&
      css`
        top: ${px2rem(86)};
        transform: translateY(-100%);
      `}
    ${(props) =>
      props.menuPosition === 1 &&
      css`
        top: 0;
        transform: translateY(-100%);
      `}
    ${(props) =>
      props.menuPosition === 2 &&
      css`
        position: absolute;
        top: 0;
        transform: translateY(0);
      `}
  }
`;

export const BackgroundColor = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  @media screen and (max-width: 997px) {
    width: 200px;
    background-color: #f7651c;
    transition: width 1s ease;
    ${(props) =>
      props.menuPosition !== 2 &&
      css`
        width: 100%;
      `}
  }
  @media screen and (min-width: 998px) {
    width: 100%;
    > div {
      &:first-of-type {
        width: 100%;
        right: 250%;
        transform: scaleX(1.5);
        position: relative;
        ${(props) =>
          props.menuPosition === 2 &&
          css`
            transition: all 0.7s ease;
          `}
        ${(props) =>
          props.animate &&
          css`
            right: 0;
            transform: scaleX(1);
          `}
        >div {
          height: 100%;
          width: 100vw;
          position: absolute;
          background-color: #f7651c;
          right: 0;
          ${(props) =>
            props.menuPosition !== 2 &&
            css`
              background-color: rgba(247, 101, 28, 0.8);
              backdrop-filter: blur(10px);
            `}
        }
      }
      &:last-of-type {
        height: 100%;
        position: absolute;
        left: 100%;
        width: 100vw;
        ${(props) =>
          props.menuPosition !== 2 &&
          css`
            background-color: #fff;
          `}
      }
    }
  }
`;

export const LogoWrapper = styled.div`
  z-index: 3;
  height: 100%;
  display: flex;
  align-items: center;
  @media screen and (min-width: 998px) {
    position: relative;
    top: 0;
    left: 0;
    padding-right: ${px2rem(120)};
    ${(props) =>
      props.menuPosition !== 2 &&
      css`
        padding-right: ${px2rem(33)};
      `}
    svg, p {
      opacity: 0;
      transform: translateX(-50%);
      ${(props) =>
        props.menuPosition === 2 &&
        css`
          transition: opacity 0.5s ease 0.6s, transform 0.8s ease 0.4s;
        `}
    }
    ${(props) =>
      props.animate &&
      css`
        svg,
        p {
          opacity: 1;
          transform: translateX(0);
        }
      `}
  }
  @media screen and (max-width: 997px) {
    justify-content: center;
    width: 210px;
    padding: 24px 30px;
    svg {
      max-width: 225px;
      width: 100%;
    }
  }
`;

export const MenuButton = styled.button`
  z-index: 3;
  padding: 20px;
  margin-right: 25px;
  outline: 0;
  > img {
    width: 32px;
    height: 25px;
  }
`;

export const NavigationBar = styled.nav`
  z-index: 3;
  width: 100%;
  max-width: ${px2rem(780)};
  color: #fff;
  ul {
    display: flex;
    justify-content: space-between;
  }
  @media screen and (max-width: 997px) {
    transform: translateX(-100%);
    transition: transform 0.5s ease;
    ${(props) =>
      props.showSideMenu &&
      css`
        transform: translateX(0);
      `}
    position: fixed;
    height: 100%;
    width: 100%;
    max-width: 100%;
    background-color: #f7651c;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;
    img {
      width: 100%;
      max-width: 230px;
    }
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 61px;
      margin-left: 10px;
      li {
        position: relative;
        margin-bottom: 42px;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          height: 6px;
          width: 6px;
          border: solid 1px #fff;
        }
        a {
          font-size: 17px;
          margin-left: 28px;
        }
      }
    }
  }
  @media screen and (min-width: 998px) {
    opacity: 0;
    transform: translateX(-25%);
    ${(props) =>
      props.menuPosition === 2 &&
      css`
        transition: opacity 0.4s ease 0.8s, transform 0.6s ease 0.6s;
      `}
    ${(props) =>
      props.animate &&
      css`
        opacity: 1;
        transform: translateX(0);
      `}
  }
`;

export const OpenButton = styled.button`
  position: absolute;
  top: 20px;
  right: 30px;
  outline: 0;
  > img {
    width: 40px;
  }
`;

export const CloseButton = styled.button`
  z-index: 6;
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0;
  left: 0;
  font-size: 18px;
  text-align: center;
  color: #000;
  background-color: #fff;
  box-shadow: 0 -3px 16px #00000029;
  outline: 0;
`;

export const Link = styled(ReactScrollLink)`
  position: relative;
  top: 0;
  left: 0;
  hr {
    height: ${px2rem(4)};
    width: 0;
    opacity: 0;
    position: absolute;
    bottom: ${px2rem(-6)};
    transition: 0.2s ease-in-out;
  }
  &:hover hr,
  &.active hr {
    background-color: #fff;
    border-radius: 0.625rem;
    width: 100%;
    opacity: 1;
  }
  @media screen and (min-width: 998px) {
    ${(props) =>
      props.menuposition !== 2 &&
      css`
        color: #282933;
        &.active hr,
        &:hover hr {
          background-color: #000;
        }
      `}
  }
`;
