import styled, { css } from "styled-components";

import { px2rem } from "../../utils";

export const Wrapper = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  @media screen and (min-width: 998px) {
    padding-bottom: 52.64%;
    height: 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  picture {
    img {
      z-index: -1;
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      @media screen and (max-width: 997px) {
        min-width: 738px;
      }
    }
  }
`;

export const TextWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: ${px2rem(320)};
  left: 0;
  @media screen and (max-width: 997px) {
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    > div {
      padding: 0 0 50px;
    }
  }
`;

export const MainText = styled.h1`
  margin-bottom: ${px2rem(30)};
  font-size: ${px2rem(72)};
  font-weight: bold;
  color: #fff;
  letter-spacing: ${px2rem(-1.8)};
  line-height: 111%;
  @media screen and (max-width: 997px) {
    max-width: 500px;
    padding: 0 20px;
    font-size: 32px;
    line-height: 42px;
  }
  @media screen and (min-width: 998px) {
    opacity: 0;
    transform: translateY(-10%);
    transition: transform 0.5s ease 1s, opacity 0.5s ease 1s;
    ${(props) =>
      props.animate &&
      css`
        transform: translateY(0);
        opacity: 1;
      `}
  }
`;

export const BoxWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 997px) {
    overflow-x: auto;
    padding-bottom: 15px;
    margin-bottom: -15px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media screen and (min-width: 998px) {
    overflow-y: hidden;
  }
  > div {
    display: flex;
    @media screen and (max-width: 997px) {
      padding: 0 20px;
    }
    @media screen and (min-width: 998px) {
      > div {
        &:nth-child(1) {
          transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1) 1s,
            opacity 1s ease 1s;
        }
        &:nth-child(2) {
          transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1) 1.1s,
            opacity 1s ease 1.1s;
        }
        &:nth-child(3) {
          transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1) 1.2s,
            opacity 1s ease 1.2s;
        }
        opacity: 0;
        transform: translateY(100%);
      }
      ${(props) =>
        props.animate &&
        css`
          > div {
            transform: translateY(0);
            opacity: 1;
          }
        `}
    }
  }
`;

export const Box = styled.div`
  height: ${px2rem(140)};
  width: ${px2rem(158)};
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  padding: ${px2rem(14)};
  border: solid ${px2rem(5)} #f7651c;
  &:not(:last-of-type) {
    margin-right: ${px2rem(35)};
  }
  p {
    font-size: ${px2rem(14)};
    color: #fff;
    text-transform: uppercase;
  }

  @media screen and (max-width: 997px) {
    height: 120px;
    width: 125px;
    padding-bottom: 10px;
    &:not(:last-of-type) {
      margin-right: 20px;
    }
    p {
      font-size: 11px;
      line-height: 18px;
    }
  }
`;
