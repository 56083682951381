import styled from 'styled-components';

import { px2rem } from '../../utils';

export const Container = styled.footer`
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  background-color: #282933;

  &::after {
    content: '';
    display: block;
    @media screen and (max-width: 997px) {
      height: 65px;
      background-color: #f7651c;
      margin-right: 94px;
    }
    @media screen and (min-width: 998px) {
      z-index: 1;
      width: ${px2rem(212)};
      height: calc(100% + ${px2rem(100)});
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: #f7651c;
      mix-blend-mode: hard-light;
      opacity: 0.9;
    }
  }

  @media screen and (min-width: 998px) {
    height: ${px2rem(248)};
    > div {
      @media screen and (min-width: 1601px) {
        max-width: 1270px;
      }
      @media screen and (max-width: 1600px) {
        max-width: none;
        margin: 0;
        padding: 0 ${px2rem(230)};
      }
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @media screen and (max-width: 997px) {
    flex-direction: column;
    padding-top: 80px;

    > div {
      flex-direction: column;
      padding: 0 0 0 40px;

      > svg {
        width: 26px;
        height: 26px;
        margin-bottom: 45px;
        path {
          fill: #fff;
        }
      }
      > div:last-of-type {
        margin-top: 20px;
        margin-right: 40px;
        svg {
          margin-left: auto;
          margin-bottom: 5px;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  @media screen and (max-width: 997px) {
    flex-direction: column;
  }
  section {
    max-width: ${px2rem(340)};
    color: #fff;

    @media screen and (min-width: 998px) {
      margin-right: ${px2rem(80)};
    }

    @media screen and (max-width: 997px) {
      margin-bottom: 45px;
    }
    strong {
      display: block;
      font-size: ${px2rem(16)};
      font-weight: bold;
      line-height: ${px2rem(24)};
      padding-bottom: ${px2rem(8)};
    }
    hr {
      width: ${px2rem(140)};
      height: ${px2rem(5)};
      background-color: #f7651c;
      margin-bottom: ${px2rem(16)};
    }
    p {
      font-size: ${px2rem(16)};
      font-weight: 300;
      line-height: ${px2rem(24)};
    }
    > div {
      display: flex;
      align-items: center;
      img {
        margin-right: 20px;
      }
    }
    &:first-of-type p {
      padding-bottom: ${px2rem(8)};
    }
  }
`;
