import React, { useState } from "react";
import LoadImage from "../LoadImage";

import Grid from "../Grid";

import { Mobile } from "../Responsivity";
import {
  Wrapper,
  ImageWrapper,
  TextWrapper,
  BoxWrapper,
  MainText,
  Box,
} from "./styles";

function FirstContainer({ setAnimationMenu }) {
  function animate() {
    setAnimationText(true);
    setAnimationMenu(true);
  }
  const [animationText, setAnimationText] = useState(false);

  return (
    <Wrapper>
      <ImageWrapper>
        <picture>
          <source
            media="(max-width:997px)"
            srcSet={require("../../assets/apresentation-mobile.jpg")}
          />
          <source
            media="(min-width:998px)"
            srcSet={require("../../assets/apresentation.jpg")}
          />
          <LoadImage
            src={require("../../assets/apresentation.jpg")}
            alt="Funcionario Veled"
            animate={animate}
          />
        </picture>
      </ImageWrapper>
      <TextWrapper>
        <Grid>
          <MainText animate={animationText}>
            Especialistas em <br className="desktop" />
            pessoas que se <br className="desktop" />
            tornam especialistas <br className="desktop" />
            no seu negócio.
          </MainText>
          <BoxWrapper animate={animationText}>
            <div>
              <Box>
                <p>Especialistas com mais de 20 anos de mercado</p>
              </Box>
              <Box>
                <p>Projetos end-to-end feitos por sócios veled</p>
              </Box>
              <Box>
                <p>Máxima customização das soluções</p>
              </Box>
            </div>
          </BoxWrapper>
        </Grid>
      </TextWrapper>
    </Wrapper>
  );
}

export default FirstContainer;
