import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import { Desktop, Mobile } from '../Responsivity';
import Grid from '../Grid';
import { Wrapper, TextContainer, TextWrapper, Image } from './styles';

function Topics(){
  const [animation, setAnimation] = useState(false);

  return(
    <Wrapper>
      <VisibilitySensor partialVisibility offset={{ bottom: 150 }} onChange={(visibility) => {visibility && setAnimation(true)}}>
        <Image animate={animation}> 
          <Desktop>
            <img src={require("../../assets/somos-a-veled.jpg")} alt="Funcionaria Veled"/>
            <span />
          </Desktop>
          <Mobile><img src={require("../../assets/somos-a-veled-mobile.jpg")} alt="Funcionaria Veled"/></Mobile>
        </Image>
      </VisibilitySensor>
      <Grid>
        <TextContainer>
          <Desktop><img src={require("../../assets/background-squares.png")} alt="Background de quadrados"/></Desktop>
          <div>
            <TextWrapper>
              <div>
                <Desktop><img src={require('../../assets/somosveled-title-image.png')} alt="Detalhe quadrado para o titulo"/></Desktop>
                <h1>Somos a Veled</h1>
              </div>
              <p>Depois de mais de 20 anos em grandes companhias, os sócios Veled criaram
                um negócio baseado no conhecimento das expectativas de clientes:
                entendimento absoluto da organização e da necessidade do time; sócios mão
                na massa nos projetos e uma relação de co-criação com as áreas de RH,
                orientados pela noção de que o relacionamento é a chave para a
                materialização das entregas para executivos e organizações.</p>
              <h2>Assim nasceu a Veled.</h2>
            </TextWrapper>
          </div>
        </TextContainer>
      </Grid>
    </Wrapper>
  )
}

export default Topics;