import styled, { css } from "styled-components";

import { px2rem } from "../../../utils";

export const Overlay = styled.div`
  z-index: 5;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: background-color 0.4s ease;
  background-color: rgba(0, 0, 0, 0);
  ${(props) =>
    props.animation &&
    css`
      background-color: rgba(0, 0, 0, 0.9);
    `}
  >div {
    height: 100%;
    width: 100%;
    transition: transform 0.4s ease;
    transform: translateY(100%);
    ${(props) =>
      props.animation &&
      css`
        transform: translateY(0);
      `}
  }
`;

export const PopUp = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  @media screen and (min-width: 998px) {
    height: 100vh;
  }
  @media screen and (max-width: 997px) {
    height: 100%;
    overflow-x: auto;
  }
  header {
    width: 100%;
    height: ${px2rem(60)};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 997px) {
      height: 60px;
    }
    > button {
      outline: 0;
      img {
        width: ${px2rem(35)};
        height: ${px2rem(35)};
        margin-right: ${px2rem(60)};
        @media screen and (max-width: 997px) {
          margin-right: ${px2rem(20)};
        }
      }
    }
  }
  > div {
    background-color: #fff;
    @media screen and (min-width: 998px) {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }
`;

export const Photo = styled.div`
  width: 100%;
  @media screen and (min-width: 998px) {
    background-color: #282933;
    height: 100%;
    max-width: 45.5%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  img {
    @media screen and (min-width: 998px) {
      box-shadow: ${px2rem(8)} ${px2rem(8)} #f7651c;
      margin-right: ${px2rem(145)};
    }
    @media screen and (max-width: 997px) {
      width: 100%;
      margin-bottom: 53px;
    }
  }
`;

export const Description = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  @media screen and (min-width: 998px) {
    max-width: 54.5%;
  }
  @media screen and (max-width: 997px) {
    padding: 0 40px 120px;
  }
  section {
    @media screen and (min-width: 998px) {
      margin-left: ${px2rem(120)};
    }
    div {
      display: flex;
      align-items: center;
      h1 {
        color: #282933;
        font-size: ${px2rem(40)};
        font-weight: bold;
        letter-spacing: ${px2rem(-1)};
        padding-right: ${px2rem(16)};
        @media screen and (max-width: 997px) {
          font-size: 24px;
          line-height: 32px;
          padding-right: 31px;
        }
      }
      img {
        height: ${px2rem(24)};
        width: ${px2rem(24)};
        @media screen and (max-width: 997px) {
          height: 17px;
          width: 17px;
        }
      }
    }
    span {
      color: #282933;
      font-weight: bold;
      display: block;
      margin-bottom: ${px2rem(18)};
      @media screen and (min-width: 998px) {
        font-size: ${px2rem(24)};
      }
    }
    strong {
      max-width: ${px2rem(500)};
      display: block;
      color: #f7651c;
      font-size: ${px2rem(16)};
      font-weight: bold;
      line-height: ${px2rem(24)};
      padding-bottom: ${px2rem(32)};
    }
    p {
      max-width: ${px2rem(610)};
      color: #282933;
      font-size: ${px2rem(16)};
      line-height: ${px2rem(24)};
      padding-bottom: ${px2rem(16)};
    }
  }
`;

export const CloseButton = styled.button`
  z-index: 6;
  position: fixed;
  width: 100%;
  height: 90px;
  bottom: 0;
  left: 0;
  font-size: 18px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 -3px 16px #00000029;
  outline: 0;
`;
