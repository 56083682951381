import styled, { css } from "styled-components";

import { px2rem } from "../../utils";

export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  @media screen and (max-width: 997px) {
    flex-direction: column;
  }
`;

export const Image = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  @media screen and (min-width: 998px) {
    margin-right: -37%;
    max-width: 45.4%;
    span {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #fff;
      transform: scaleX(1.8) skew(32deg, 0);
      transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1);
      ${(props) =>
        props.animate &&
        css`
          transform: scaleX(1) skew(0, 0) translateX(100%);
        `}
    }
  }
  img {
    z-index: -1;
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

export const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  @media screen and (min-width: 998px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  @media screen and (max-width: 997px) {
    background-color: #fff;
    padding: 50px 20px 80px;
    margin-top: -40%;
  }
  > img {
    position: absolute;
    width: 65%;
    max-width: 1300px;
    height: 100%;
    top: 0;
    right: 0;
  }
  > div {
    height: calc(100% - ${px2rem(90)});
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;

    @media screen and (min-width: 998px) {
      max-width: 80%;
      padding-left: 12%;
      padding-top: ${px2rem(60)};
    }
    @media screen and (min-width: 998px) and (max-width: 1680px) {
      max-width: 76%;
      padding-left: 10%;
    }
    @media screen and (min-width: 998px) and (max-width: 1440px) {
      max-width: 73%;
      padding-left: 9%;
    }
  }
`;

export const TextWrapper = styled.section`
  width: 100%;
  max-width: ${px2rem(656)};
  z-index: 1;
  > div {
    position: relative;
    top: 0;
    left: 0;
    h1 {
      font-size: ${px2rem(64)};
      font-weight: bold;
      letter-spacing: ${px2rem(-1)};
      color: #282933;
      padding-bottom: ${px2rem(30)};
      margin-left: 10%;
      @media screen and (max-width: 997px) {
        font-size: 30px;
        line-height: 38px;
        padding-bottom: 15px;
      }
    }
    img {
      position: absolute;
      width: ${px2rem(129)};
      height: ${px2rem(129)};
      bottom: ${px2rem(50)};
      left: 0;
    }
  }
  p {
    font-size: ${px2rem(16)};
    color: #474957;
    font-weight: 300;
    line-height: ${px2rem(32)};
    padding-bottom: ${px2rem(23)};
    @media screen and (max-width: 997px) {
      padding: 0 10px 40px;
    }
  }
  h2 {
    width: 100%;
    font-size: ${px2rem(24)};
    color: #f7651c;
    letter-spacing: ${px2rem(-0.6)};
    font-weight: 400;
  }
`;
