import React, { FC } from 'react';
import MediaQuery from 'react-responsive';

export const Desktop = ({ children }) => (
  <MediaQuery minWidth={998}>
    {matches => {
      if (!matches) return null;
      return children;
    }}
  </MediaQuery>
);
export const Mobile = ({ children }) => (
  <MediaQuery maxWidth={997}>
    {matches => {
      if (!matches) return null;
      return children;
    }}
  </MediaQuery>
);