import React, { useEffect, useState, useRef } from 'react';

import { Wrapper, Slider, Item, Dots } from './styles';

function TopicSlider(){
  const [slidePosition, setSlidePosition] = useState(1);
  const slide = useRef();

  useEffect(() => {
    const interval = setInterval(() => {
      if((slide.current.scrollLeft + slide.current.offsetWidth) <= (slide.current.scrollWidth * 0.395)){
        setSlidePosition(1);
      }else if((slide.current.scrollLeft + slide.current.offsetWidth) <= (slide.current.scrollWidth * 0.65)){
        setSlidePosition(2);
      }else if((slide.current.scrollLeft + slide.current.offsetWidth) <= (slide.current.scrollWidth * 0.895)){
        setSlidePosition(3);
      }else {
        setSlidePosition(4);
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);


  return(
    <Wrapper>
      <Slider ref={slide}>
        <div>
          <Item>
            <div>
              <img src={require("../../../assets/slide-1.jpg")} alt="Funcionarios da Veled" />
              <h2>
                <div><span /><span /><span /></div>
                Experiência <br /> e conhecimento <br /> de mercado.
              </h2>
            </div>
            <p>A Veled entende que é preciso ir além daquilo que está naturalmente explícito. 
            Alinhamento de propósitos, identificação com os traços culturais e valores da sua organização 
            são determinantes no nosso processo.</p>
          </Item>
          <Item >
            <div>
              <img src={require("../../../assets/slide-2.jpg")} alt="Funcionarios da Veled" />
              <h2>
                <div><span /><span /><span /></div>
                Partnership de RH para empresas com visão estratégica de RH.
              </h2>
            </div>
            <p>A Veled nasceu para ser um parceiro estratégico das áreas de RH, 
              participando da co-criação das estratégias, planos e decisões, 
              indo além da condução de projetos específicos de seleção de executivos.</p>
          </Item>
          <Item>
            <div>
              <img src={require("../../../assets/slide-3.jpg")} alt="Funcionarios da Veled" />
              <h2>
                <div><span /><span /><span /></div>
                Projetos de RH com a máxima customização possível.
              </h2>
            </div>
            <p>Os especialistas da Veled vão fundo para entender a cultura, 
              os valores e o perfil do seu time. É o único método consistente para construir 
              as melhores equipes para executar a transformação que sua companhia precisa.</p>
          </Item>
          <Item>
            <div>
              <img src={require("../../../assets/slide-4.jpg")} alt="Funcionarios da Veled" />
              <h2>
                <div><span /><span /><span /></div>
                Plano de carreira? Nós chamamos de planejamento de vida.
                </h2>
            </div>
            <p>A carreira tem impacto direto na vida de uma pessoa. Por isso a Veled considera 
              tão importante o perfil de cada executivo, seus valores, habilidades, vocações, 
              tendências do mercado e momento pessoal.</p>
          </Item>
        </div>
      </Slider>
      <Dots slidePosition={slidePosition}>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </Dots>
    </Wrapper>
  )
}

export default TopicSlider;