import React, { useEffect, useState, useRef } from 'react';
import { Element } from 'react-scroll';
import VisibilitySensor from 'react-visibility-sensor';

import { Desktop } from '../Responsivity';
import { Wrapper, ImageWrapper, Animation, Container, Slider, Dots} from './styles';

function NossasEntregas() {
  const [animation, setAnimation] = useState(false);
  const [slidePosition, setSlidePosition] = useState(1);
  const slide = useRef();

  useEffect(() => {
    const interval = setInterval(() => {
      if((slide.current.scrollLeft + slide.current.offsetWidth) <= (slide.current.scrollWidth * 0.8)){
        setSlidePosition(1);
      }else{
        setSlidePosition(2);
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <Element name="nossas-entregas">
    <VisibilitySensor partialVisibility offset={{ bottom: 500 }} onChange={(visibility) => {visibility && setAnimation(true)}}>
      <Wrapper>
        <Desktop>
          <ImageWrapper>
            <div>
              <img src={require("../../assets/nossas-entregas.jpg")} alt="Funcionario Veled"/>
            </div>
              <Animation animate={animation}>
                <span />
              </Animation>
          </ImageWrapper>
        </Desktop>
        
        <Container>
          <h1>Nossas <br />Entregas</h1>
          <Slider ref={slide}>
              <div>
                <section>
                  <h2>Executive <br/>Search</h2>
                  <strong>Na sua empresa, só existe um ativo mais importante que pessoas: as pessoas certas.</strong>
                  <p>A Veled desenvolve projetos de Executive Search que vão a fundo também em
                    pontos intangíveis. Em relação às organizações, consideramos a cultura da
                    companhia, seus valores e o perfil do time. No que se refere aos executivos,
                    avaliamos competências, trajetória e mais um conjunto de habilidades
                    pessoais. Esses aspectos fazem toda a diferença na escolha do líder ideal para
                    sua equipe.</p>
                </section>
              </div>
              <div>
                <section>
                  <h2>Leadership <br/>Consulting</h2>
                  <strong>Os executivos perfeitos podem estar onde você menos espera: no seu time.</strong>
                  <p>Desenvolver, engajar, transformar. A Veled entrega projetos feitos sob 
                    medida para a cultura, a estratégia e o momento da sua organização. 
                    Com a Veled, você desenvolve o máximo potencial da sua equipe.</p>
                </section>
              </div>
          </Slider>

          <Dots slidePosition={slidePosition}>
            <li></li>
            <li></li>
          </Dots>
        </Container>
      </Wrapper>
    </VisibilitySensor>
    </Element>
  )
}

export default NossasEntregas;