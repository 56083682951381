import styled, { css } from "styled-components";

import { px2rem } from "../../../../utils";

export const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #282933;
  display: flex;
  overflow: hidden;
  > img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 45.4%;
    width: 54.6%;
    @media screen and (min-width: 1366px) and (max-width: 1920px) {
      width: 1048px;
    }
    ${(props) =>
      props.textRight &&
      css`
        left: auto;
        right: 54.6%;
        width: 45.4%;
        @media screen and (min-width: 1366px) and (max-width: 1920px) {
          width: 872px;
        }
      `}
  }
`;

export const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const TextWrapper = styled.section`
  position: relative;
  max-width: ${px2rem(500)};
  ${(props) =>
    props.textRight &&
    css`
      margin-left: 48%;
    `}
  width: 100%;
  display: flex;
  flex-direction: column;
  h2 {
    width: 100%;
    font-size: ${px2rem(40)};
    color: #fff;
    letter-spacing: ${px2rem(-1)};
    line-height: ${px2rem(48)};
    font-weight: bold;
    div {
      margin-bottom: 7px;
      span {
        display: inline-block;
        height: 13px;
        width: 13px;
        border: 1.5px solid #f7651c;
        margin-right: 10px;
      }
    }
  }
  p {
    font-size: ${px2rem(16)};
    color: #fff;
    font-weight: 300;
    line-height: ${px2rem(32)};
  }
`;

export const HeightSet = styled.div`
  @media screen and (min-width: 1367px) {
    height: 462px;
  }
  @media screen and (max-width: 1366px) {
    height: 0;
    padding-bottom: 24.11%;
  }
`;

export const Animation = styled.div`
  position: absolute;
  overflow: hidden;
  height: 100%;
  top: 0;
  span {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #282933;
    transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1),
      right 1.5s cubic-bezier(0.16, 1, 0.3, 1);
    ${(props) =>
      props.redBackground &&
      css`
        background-color: #f7651c;
      `}
  }

  ${(props) =>
    !props.textRight &&
    css`
      width: 54.6%;
      right: 0;
      span {
        transform: scaleX(1.5) skew(-32deg, 0);
        ${(props) =>
          props.animate &&
          css`
            right: 100%;
            transform: scaleX(1) skew(0, 0);
          `}
      }
    `}

  ${(props) =>
    props.textRight &&
    css`
      width: 45.4%;
      left: 0;
      span {
        transform: scaleX(1.8) skew(32deg, 0);
        ${(props) =>
          props.animate &&
          css`
            right: -100%;
            transform: scaleX(1) skew(0, 0);
          `}
      }
    `}
`;
