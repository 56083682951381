import styled from 'styled-components';
//import { px2rem } from '../utils';

const Grid = styled.div `
  width: 100%;
  @media screen and (min-width: 997px){
    max-width: 1360px;
    padding: 0 40px;
    margin: 0 auto;
  }
  @media screen and (max-width: 998px){
    padding: 0 20px;
  }
`;

export default Grid;