import React, {useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

export default function LoadImage({ alt, innerRef, animate, ...props }) {
  const [loaded, setLoaded] = useState(false);
  const unMounted = useRef(false);
  useEffect(() => {
    return () => {
      unMounted.current = true;
    };
  }, []); //eslint-disable-line
  return (
    <img
      {...props}
      alt={alt}
      className={classNames('load-image', props.className, { loaded })}
      onLoad={() => {
        if (!unMounted.current) {
          setLoaded(true);
          animate(true);
        }
      }}
      ref={innerRef}
    />
  );
}