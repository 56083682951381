import React from 'react';

import { ReactComponent as Logo } from '../../assets/logo-veled.svg';
import { LogoText, Wrapper } from './styles';

function LogoVeled({ text = true }){
  return(
    <>
      <Wrapper>
        <Logo />
          {text && 
            <>
              <LogoText>We</LogoText>
              <LogoText>Value</LogoText>
              <LogoText>You</LogoText>
            </>
          }
      </Wrapper>
    </>
  )
}

export default LogoVeled;