import styled from "styled-components";
import { Link } from "react-router-dom";

import { px2rem } from "../../utils";

export const Container = styled.div`
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 998px) {
    padding-top: ${px2rem(133)};
  }
  @media screen and (max-width: 997px) {
    > div {
      padding: 0 40px;
    }
  }
  h1 {
    font-size: ${px2rem(64)};
    font-weight: bold;
    letter-spacing: ${px2rem(-1)};
    line-height: ${px2rem(64)};
    color: #f7651c;
    padding-bottom: ${px2rem(34)};
    @media screen and (max-width: 997px) {
      font-size: 34px;
      line-height: 40px;
      padding-bottom: 59px;
    }
  }
  /*Background*/
  > img {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 54.65%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 998px){
    margin-bottom: ${px2rem(180)};
  }
  >div{
    @media screen and (min-width: 998px){
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: ${px2rem(48)};
      grid-row-gap: ${px2rem(80)};
    }
    @media screen and (max-width: 997px){
      display: flex;
      flex-direction: column;
      &:last-of-type {
        margin-bottom: 30px;
      }
    }
  }
`;

export const PhotoWrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: 0 ${px2rem(13)} ${px2rem(26)} #00000012;
  @media screen and (max-width: 997px){
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-bottom: 50px;
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 998px) {
      max-width: 48%;
      padding-left: ${px2rem(32)};
    }
    @media screen and (max-width: 997px) {
      padding: 0 30px;
    }
    strong {
      display: flex;
      align-items: center;
      font-size: ${px2rem(24)};
      font-weight: bold;
      letter-spacing: ${px2rem(-0.6)};
      line-height: ${px2rem(32)};
      color: #282933;
      @media screen and (max-width: 997px) {
        margin-top: 29px;
        span {
          margin-right: 30px;
        }
      }
      @media screen and (min-width: 998px) {
        span {
          margin-right: ${px2rem(8)};
        }
      }
      img {
        width: ${px2rem(17)};
      }
    }
    > p {
      font-size: ${px2rem(16)};
      font-weight: bold;
      color: #282933;
      margin-bottom: ${px2rem(16)};
    }
  }
  img {
    width: 100%;
    @media screen and (min-width: 998px) {
      max-width: ${px2rem(286)};
      height: 100%;
    }
  }
`;

export const LinkModal = styled(Link)`
  cursor: pointer;
  &:hover {
    hr {
      width: 100%;
      height: 2px;
      opacity: 1;
    }
  }
  p {
    font-size: ${px2rem(16)};
    font-weight: 400;
    line-height: ${px2rem(24)};
    color: #282933;
    margin-bottom: ${px2rem(16)};
    @media screen and (max-width: 997px) {
      margin-bottom: 36px;
    }
  }
  img {
    width: 100%;
    @media screen and (min-width: 998px) {
      max-width: ${px2rem(286)};
    }
  }
`;

export const InfoLink = styled.div`
  width: 100%;
  max-width: ${px2rem(102)};
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 997px) {
    width: 100%;
    max-width: 191px;
    padding-bottom: 31px;
  }
  span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${px2rem(16)};
    font-weight: 300;
    line-height: ${px2rem(24)};
    text-transform: uppercase;
    color: #f7651c;
    padding-bottom: ${px2rem(10)};
    @media screen and (max-width: 997px) {
      max-width: 143px;
      margin: 0 auto;
      padding-bottom: ${px2rem(15)};
    }
    img {
      width: ${px2rem(9)};
    }
  }
  svg rect {
    fill: #f7651c;
    stroke: #f7651c;
  }
  hr {
    width: 0;
    height: 2px;
    opacity: 0;
    background-color: #f7651c;
    transition: 0.2s linear;
  }
`;
