import React, { useState, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";
import { Element } from "react-scroll";
import { useFormik } from "formik";
import validationSchema from "./validate";
import MaskedInput from "react-text-mask";

import { Desktop, Mobile } from "../Responsivity";
import { ReactComponent as RightArrow } from "../../assets/right-arrow.svg";
import {
  Container,
  Map,
  Wrapper,
  InputContent,
  ButtonWrapper,
  ErrorMessage,
} from "./styles";

function Contact() {
  const [submitStatus, setSubmitStatus] = useState("pending");
  const submitButton = useRef();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      company: "",
      position: "",
      message: "",
    },
    validateOnMount: true,
    isInitialValid: false,
    validationSchema,
    onSubmit: async (
      { name, email, phone, company, position, message },
      actions
    ) => {
      setSubmitStatus("sending");

      const bodyFormData = new FormData();
      bodyFormData.set("name", name);
      bodyFormData.set("email", email);
      bodyFormData.set("phone", phone);
      bodyFormData.set("company", company);
      bodyFormData.set("position", position);
      bodyFormData.set("message", message);

      axios
        .post("/contact/send.php", bodyFormData)
        .then((response) => {
          setSubmitStatus("sended");
          actions.resetForm();
          setTimeout(() => {
            setSubmitStatus("pending");
          }, 2000);
          submitButton.current.blur();
        })
        .catch(() => {
          setSubmitStatus("error");
          //actions.resetForm();
          setTimeout(() => {
            setSubmitStatus("pending");
          }, 2000);
          submitButton.current.blur();
        });
    },
  });

  return (
    <Element name="fale-com-a-veled">
      <Container>
        <Desktop>
          <Map>
            <img
              src={require("../../assets/map.jpg")}
              alt="Localização Veled no mapa"
            />
          </Map>
        </Desktop>
        <Mobile>
          <img
            src={require("../../assets/map-mobile.jpg")}
            alt="Localização Veled no mapa"
          />
        </Mobile>

        <Wrapper submit={submitStatus}>
          <h1>
            Vamos pensar juntos sobre
            <br />
            Gestão e Pessoas?
          </h1>
          <form onSubmit={formik.handleSubmit}>
            <InputContent>
              <input
                placeholder="Nome"
                name="name"
                type="text"
                {...formik.getFieldProps("name")}
              />
              <label>Nome</label>
              {formik.touched.name && formik.errors.name ? (
                <ErrorMessage>{formik.errors.name}</ErrorMessage>
              ) : null}
            </InputContent>

            <div>
              <InputContent>
                <input
                  placeholder="Email"
                  name="email"
                  type="email"
                  {...formik.getFieldProps("email")}
                />
                <label>Email</label>
                {formik.touched.email && formik.errors.email ? (
                  <ErrorMessage>{formik.errors.email}</ErrorMessage>
                ) : null}
              </InputContent>

              <InputContent>
                <MaskedInput
                  placeholder="Telefone"
                  name="phone"
                  type="text"
                  mask={[
                    "(",
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  guide={false}
                  {...formik.getFieldProps("phone")}
                />
                <label>Telefone</label>
                {formik.touched.phone && formik.errors.phone ? (
                  <ErrorMessage>{formik.errors.phone}</ErrorMessage>
                ) : null}
              </InputContent>
            </div>

            <div>
              <InputContent>
                <input
                  placeholder="Empresa"
                  name="company"
                  type="text"
                  {...formik.getFieldProps("company")}
                />
                <label>Empresa</label>
                {formik.touched.company && formik.errors.company ? (
                  <ErrorMessage>{formik.errors.company}</ErrorMessage>
                ) : null}
              </InputContent>

              <InputContent>
                <input
                  placeholder="Cargo"
                  name="position"
                  type="text"
                  {...formik.getFieldProps("position")}
                />
                <label>Cargo</label>
                {formik.touched.position && formik.errors.position ? (
                  <ErrorMessage>{formik.errors.position}</ErrorMessage>
                ) : null}
              </InputContent>
            </div>

            <InputContent className="textarea-content">
              <TextareaAutosize
                placeholder="Mensagem"
                name="message"
                type="text"
                {...formik.getFieldProps("message")}
              />
              <label>Mensagem</label>
              {formik.touched.message && formik.errors.message ? (
                <ErrorMessage>{formik.errors.message}</ErrorMessage>
              ) : null}
            </InputContent>

            <ButtonWrapper submit={submitStatus}>
              <button type="submit" ref={submitButton}>
                {submitStatus === "pending" && (
                  <>
                    Enviar <RightArrow />{" "}
                    <Desktop>
                      <span>
                        Enviar
                        <RightArrow />
                      </span>
                    </Desktop>
                  </>
                )}
                {submitStatus === "sending" && (
                  <>
                    Enviando...{" "}
                    <Desktop>
                      <span>Enviando...</span>
                    </Desktop>
                  </>
                )}
                {submitStatus === "sended" && <>Enviado com sucesso!</>}
                {submitStatus === "error" && <>Falha ao enviar email</>}
              </button>
            </ButtonWrapper>
          </form>
        </Wrapper>
      </Container>
    </Element>
  );
}

export default Contact;
