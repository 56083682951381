import styled from "styled-components";

import { px2rem } from "../../../utils";

export const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  background-color: #f7651c;
  align-items: center;
  > img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 45.4%;
  }
`;

export const TextWrapper = styled.section`
  position: relative;
  width: 100%;
  max-width: ${px2rem(480)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: ${px2rem(120)};
  h2 {
    font-size: ${px2rem(40)};
    font-weight: bold;
    letter-spacing: ${px2rem(-1)};
    line-height: ${px2rem(48)};
    color: #fff;
    padding-bottom: ${px2rem(16)};
    div {
      margin-bottom: 7px;
      span {
        display: inline-block;
        height: 13px;
        width: 13px;
        border: 1.5px solid #fff;
        margin-right: 10px;
      }
    }
  }
  p {
    font-size: ${px2rem(16)};
    color: #474957;
    font-weight: 300;
    line-height: ${px2rem(32)};
    color: #fff;
  }
`;
